@charset "UTF-8";
/* BASICS */
.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black;
  direction: ltr;
}

/* PADDING */
.CodeMirror-lines {
  padding: 4px 0; /* Vertical padding around content */
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px; /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: white; /* The little square between H and V scrollbars */
}

/* GUTTER */
.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
}

.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}

.CodeMirror-guttermarker {
  color: black;
}

.CodeMirror-guttermarker-subtle {
  color: #999;
}

/* CURSOR */
.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0;
}

/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7;
}

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-fat-cursor .CodeMirror-line::selection,
.cm-fat-cursor .CodeMirror-line > span::selection,
.cm-fat-cursor .CodeMirror-line > span > span::selection {
  background: transparent;
}

.cm-fat-cursor .CodeMirror-line::-moz-selection,
.cm-fat-cursor .CodeMirror-line > span::-moz-selection,
.cm-fat-cursor .CodeMirror-line > span > span::-moz-selection {
  background: transparent;
}

.cm-fat-cursor {
  caret-color: transparent;
}

@-moz-keyframes blink {
  50% {
    background-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    background-color: transparent;
  }
}
@keyframes blink {
  50% {
    background-color: transparent;
  }
}
/* Can style cursor different in overwrite (non-insert) mode */
.cm-tab {
  display: inline-block;
  text-decoration: inherit;
}

.CodeMirror-rulers {
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
  bottom: 0;
  overflow: hidden;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0;
  bottom: 0;
  position: absolute;
}

/* DEFAULT THEME */
.cm-s-default .cm-header {
  color: blue;
}

.cm-s-default .cm-quote {
  color: #090;
}

.cm-negative {
  color: #d44;
}

.cm-positive {
  color: #292;
}

.cm-header, .cm-strong {
  font-weight: bold;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: #708;
}

.cm-s-default .cm-atom {
  color: #219;
}

.cm-s-default .cm-number {
  color: #164;
}

.cm-s-default .cm-def {
  color: #00f;
}

.cm-s-default .cm-variable-2 {
  color: #05a;
}

.cm-s-default .cm-variable-3, .cm-s-default .cm-type {
  color: #085;
}

.cm-s-default .cm-comment {
  color: #a50;
}

.cm-s-default .cm-string {
  color: #a11;
}

.cm-s-default .cm-string-2 {
  color: #f50;
}

.cm-s-default .cm-meta {
  color: #555;
}

.cm-s-default .cm-qualifier {
  color: #555;
}

.cm-s-default .cm-builtin {
  color: #30a;
}

.cm-s-default .cm-bracket {
  color: #997;
}

.cm-s-default .cm-tag {
  color: #170;
}

.cm-s-default .cm-attribute {
  color: #00c;
}

.cm-s-default .cm-hr {
  color: #999;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-error {
  color: #f00;
}

.cm-invalidchar {
  color: #f00;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

/* Default styles for common addons */
div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}

.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3);
}

.CodeMirror-activeline-background {
  background: #e8f2ff;
}

/* STOP */
/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */
.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white;
}

.CodeMirror-scroll {
  overflow: scroll !important; /* Things will break if this is overridden */
  /* 50px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  height: 100%;
  outline: none; /* Prevent dragging from highlighting the element */
  position: relative;
  z-index: 0;
}

.CodeMirror-sizer {
  position: relative;
  border-right: 50px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
  outline: none;
}

.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}

.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
}

.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0;
}

.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3;
}

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -50px;
}

.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}

.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;
}

.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}

.CodeMirror-gutter-wrapper ::selection {
  background-color: transparent;
}

.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: transparent;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px; /* prevents collapsing before first draw */
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  /* Reset some styles that the rest of the page might have set */
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
}

.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px; /* Force widget margins to stay inside of the container */
}

.CodeMirror-rtl pre {
  direction: rtl;
}

.CodeMirror-code {
  outline: none;
}

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-cursor {
  position: absolute;
  pointer-events: none;
}

.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}

div.CodeMirror-dragcursors {
  visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}

.CodeMirror-crosshair {
  cursor: crosshair;
}

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}

.cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, 0.4);
}

/* Used to force a border model for a node */
.cm-force-border {
  padding-right: 0.1px;
}

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}
/* See issue #2901 */
.cm-tab-wrap-hack:after {
  content: "";
}

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
  background: none;
}

.CodeMirror {
  box-sizing: border-box;
  height: auto;
  border: 1px solid #ddd;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px;
  font: inherit;
  z-index: 1;
  word-wrap: break-word;
}

.CodeMirror-scroll {
  cursor: text;
}

.CodeMirror-fullscreen {
  background: #fff;
  position: fixed !important;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 9;
  border-right: none !important;
  border-bottom-right-radius: 0 !important;
}

.CodeMirror-sided {
  width: 50% !important;
}

.editor-toolbar {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  padding: 0 10px;
  border-top: 1px solid #bbb;
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.editor-toolbar:after,
.editor-toolbar:before {
  display: block;
  content: " ";
  height: 1px;
}

.editor-toolbar:before {
  margin-bottom: 8px;
}

.editor-toolbar:after {
  margin-top: 8px;
}

.editor-toolbar.fullscreen {
  width: 100%;
  height: 50px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  background: #fff;
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 9;
}

.editor-toolbar.fullscreen::before {
  width: 20px;
  height: 50px;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.editor-toolbar.fullscreen::after {
  width: 20px;
  height: 50px;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
}

.editor-toolbar a,
.editor-toolbar button {
  background: transparent;
  display: inline-block;
  text-align: center;
  text-decoration: none !important;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
}

.editor-toolbar a.active,
.editor-toolbar a:hover,
.editor-toolbar button.active,
.editor-toolbar button:hover {
  background: #fcfcfc;
  border-color: #95a5a6;
}

.editor-toolbar a:before,
.editor-toolbar button:before {
  line-height: 30px;
}

.editor-toolbar i.separator {
  display: inline-block;
  width: 0;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #fff;
  color: transparent;
  text-indent: -10px;
  margin: 0 6px;
}

.editor-toolbar a.fa-header-x:after {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 65%;
  vertical-align: text-bottom;
  position: relative;
  top: 2px;
}

.editor-toolbar a.fa-header-1:after {
  content: "1";
}

.editor-toolbar a.fa-header-2:after {
  content: "2";
}

.editor-toolbar a.fa-header-3:after {
  content: "3";
}

.editor-toolbar a.fa-header-bigger:after {
  content: "▲";
}

.editor-toolbar a.fa-header-smaller:after {
  content: "▼";
}

.editor-toolbar.disabled-for-preview button:not(.no-disable) {
  opacity: 0.6;
  pointer-events: none;
}

@media only screen and (max-width: 700px) {
  .editor-toolbar a.no-mobile {
    display: none;
  }
}
.editor-statusbar {
  padding: 8px 10px;
  font-size: 12px;
  color: #959694;
  text-align: right;
}

.editor-statusbar span {
  display: inline-block;
  min-width: 4em;
  margin-left: 1em;
}

.editor-statusbar .lines:before {
  content: "lines: ";
}

.editor-statusbar .words:before {
  content: "words: ";
}

.editor-statusbar .characters:before {
  content: "characters: ";
}

.editor-preview {
  padding: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fafafa;
  z-index: 7;
  overflow: auto;
  display: none;
  box-sizing: border-box;
}

.editor-preview-side {
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 50%;
  top: 50px;
  right: 0;
  background: #fafafa;
  z-index: 9;
  overflow: auto;
  display: none;
  box-sizing: border-box;
  border: 1px solid #ddd;
  word-wrap: break-word;
}

.editor-preview-active-side {
  display: block;
}

.editor-preview-active {
  display: block;
}

.editor-preview > p,
.editor-preview-side > p {
  margin-top: 0;
}

.editor-preview pre,
.editor-preview-side pre {
  background: #eee;
  margin-bottom: 10px;
}

.editor-preview table td,
.editor-preview table th,
.editor-preview-side table td,
.editor-preview-side table th {
  border: 1px solid #ddd;
  padding: 5px;
}

.CodeMirror .CodeMirror-code .cm-tag {
  color: #63a35c;
}

.CodeMirror .CodeMirror-code .cm-attribute {
  color: #795da3;
}

.CodeMirror .CodeMirror-code .cm-string {
  color: #183691;
}

.CodeMirror .CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror .CodeMirror-code .cm-header-1 {
  font-size: 200%;
  line-height: 200%;
}

.CodeMirror .CodeMirror-code .cm-header-2 {
  font-size: 160%;
  line-height: 160%;
}

.CodeMirror .CodeMirror-code .cm-header-3 {
  font-size: 125%;
  line-height: 125%;
}

.CodeMirror .CodeMirror-code .cm-header-4 {
  font-size: 110%;
  line-height: 110%;
}

.CodeMirror .CodeMirror-code .cm-comment {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
}

.CodeMirror .CodeMirror-code .cm-link {
  color: #7f8c8d;
}

.CodeMirror .CodeMirror-code .cm-url {
  color: #aab2b3;
}

.CodeMirror .CodeMirror-code .cm-strikethrough {
  text-decoration: line-through;
}

.CodeMirror .CodeMirror-placeholder {
  opacity: 0.5;
}

.editor .CodeMirror {
  color: #333;
  font-weight: normal;
  line-height: 1.45;
}
.editor .CodeMirror code {
  border: none;
}
.editor .CodeMirror .CodeMirror-code span {
  color: #333 !important;
}

@keyframes animation-spin {
  to {
    transform: rotate(1turn);
  }
}
@-webkit-keyframes animation-spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}
.editor-loading::before {
  -webkit-animation: animation-spin 800ms infinite linear;
  -o-animation: animation-spin 800ms infinite linear;
  animation: animation-spin 800ms infinite linear;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 3px solid #e8e8e8;
  border-right-color: var(--primary);
  display: inline-block;
  position: relative;
  float: right;
  content: "";
  vertical-align: middle;
}

.form-group .ql-container {
  background-color: white;
}

.editor-container {
  overflow: hidden;
}

.editor.hashtags__container > p.help-text {
  margin-top: -1rem;
  margin-bottom: 1.5rem;
}

.form-control[type=richtext] > p.help-text {
  margin-top: 0.2rem !important;
}
.form-control[type=richtext] .ql-snow.ql-toolbar button {
  width: 22px;
  margin: 1px 0;
}
.form-control[type=richtext] .ql-snow.ql-toolbar + .ql-container.ql-snow {
  border-radius: 0;
}

.proposal_custom_fields_editor .form-wrap.form-builder .form-group > .form-control[type=richtext] {
  padding: 0;
  border: none;
}

.awesome_autosave-notice {
  background-color: rgba(var(--success-rgb), 0.8);
  position: fixed;
  top: 0;
  right: 0;
  margin: 2em;
  padding: 0.5em 1em;
  border-radius: 4px;
  border: 1px solid var(--success);
  font-weight: 600;
  color: white;
  z-index: 1000;
}
.awesome_autosave-notice.error {
  background-color: rgba(var(--alert-rgb), 0.8);
}

:root {
  --voting-cards-weight-0: #8b898b;
  --voting-cards-weight-1: #f44336;
  --voting-cards-weight-2: #ffc107;
  --voting-cards-weight-3: #4caf50;
}

.voting-voting_cards {
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #d9d9d9;
}
.voting-voting_cards-card a {
  margin-bottom: 0;
}
.voting-voting_cards-card a > svg {
  margin-bottom: 2px;
  vertical-align: middle;
}
.voting-voting_cards-card a.button.weight_0 {
  background-color: var(--voting-cards-weight-0);
}
.voting-voting_cards-card a.button.weight_1 {
  background-color: var(--voting-cards-weight-1);
}
.voting-voting_cards-card a.button.weight_2 {
  background-color: var(--voting-cards-weight-2);
}
.voting-voting_cards-card a.button.weight_3 {
  background-color: var(--voting-cards-weight-3);
}
.voting-voting_cards-card .votes_counter .weight_0 {
  color: var(--voting-cards-weight-0);
}
.voting-voting_cards-card .votes_counter .weight_1 {
  color: var(--voting-cards-weight-1);
}
.voting-voting_cards-card .votes_counter .weight_2 {
  color: var(--voting-cards-weight-2);
}
.voting-voting_cards-card .votes_counter .weight_3 {
  color: var(--voting-cards-weight-3);
}
.voting-voting_cards.current-choice {
  text-align: center;
  margin-bottom: 0;
  border-bottom: none;
}
.voting-voting_cards.current-choice .vote-card {
  margin-top: 0.5rem;
}
.voting-voting_cards.current-choice .vote-card .vote-label {
  margin-bottom: 1rem;
  color: #1a181d;
}
.voting-voting_cards.current-choice .change-vote-button:hover {
  font-size: 1rem;
  margin-top: 0;
}
.voting-voting_cards.loading {
  opacity: 0.3;
  pointer-events: none;
  position: relative;
}
.voting-voting_cards.loading::before {
  -webkit-animation: animation-spin 800ms infinite linear;
  -o-animation: animation-spin 800ms infinite linear;
  animation: animation-spin 800ms infinite linear;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 3px solid #e8e8e8;
  border-right-color: var(--primary);
  display: inline-block;
  position: relative;
  z-index: 1000;
  content: "";
  vertical-align: middle;
  position: absolute;
  top: calc(50% - 12.5px);
  left: calc(50% - 12.5px);
}
.voting-voting_cards .vote-title {
  text-align: center;
  font-size: 1.5rem;
}
.voting-voting_cards .votes_counter {
  letter-spacing: 0.2rem;
  font-size: 1rem;
}
.voting-voting_cards .vote-link.hidden {
  display: none;
}
.voting-voting_cards .vote-card {
  display: inline-block;
}
.voting-voting_cards .vote-card .vote-label {
  font-size: 1rem;
  margin-bottom: 0;
}
.voting-voting_cards .vote-card > svg {
  display: inline-block;
  width: 3rem;
  height: 6rem;
  vertical-align: middle;
  margin: -0.5rem 0 0 0.5rem;
  max-width: 100%;
  transition: transform 150ms ease-in-out;
}
.voting-voting_cards .vote-card > svg:hover {
  transform: scale(1.15) rotate(-5deg);
}
.voting-voting_cards .vote-card.weight_3 {
  color: var(--voting-cards-weight-3);
}
.voting-voting_cards .vote-card.weight_3 > svg {
  fill: var(--voting-cards-weight-3);
}
.voting-voting_cards .vote-card.weight_2 {
  color: var(--voting-cards-weight-2);
}
.voting-voting_cards .vote-card.weight_2 > svg {
  fill: var(--voting-cards-weight-2);
}
.voting-voting_cards .vote-card.weight_1 {
  color: var(--voting-cards-weight-1);
}
.voting-voting_cards .vote-card.weight_1 > svg {
  fill: var(--voting-cards-weight-1);
}
.voting-voting_cards .vote-card.voted > svg {
  transform: scale(1.15) rotate(-5deg);
}
.voting-voting_cards .voting-button {
  background: none;
  border: none;
  color: #fff;
  font-weight: bold;
}
.voting-voting_cards .dim {
  opacity: 0.3;
  pointer-events: none;
}
.voting-voting_cards .disabled {
  pointer-events: none;
}
.voting-voting_cards .vote-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
}
.voting-voting_cards .vote-block .vote-count {
  font-size: 2rem;
  font-weight: bolder;
  margin-bottom: 0;
}
.voting-voting_cards .change-vote-button {
  transition: all 150ms ease-in-out;
}
.voting-voting_cards .change-vote-button:hover {
  text-decoration: none;
  font-size: 0.9rem;
  margin-top: 0.1rem;
}
.voting-voting_cards .button.abstain-button {
  text-transform: uppercase;
  color: var(--voting-cards-weight-0);
  border-color: var(--voting-cards-weight-0);
  background-color: transparent;
}
.voting-voting_cards .button.abstain-button.voted {
  background-color: var(--voting-cards-weight-0);
  color: #fff;
  border-color: transparent;
}
.voting-voting_cards .button.abstain-button.voted.disabled {
  opacity: 1;
}

.decidim_awesome-custom_fields dd > div:last-child {
  margin-bottom: 1rem;
}
.decidim_awesome-custom_fields dd[name=textarea] > div > p {
  margin-top: 1rem;
  margin-bottom: 0;
}