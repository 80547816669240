:root {
  --voting-cards-weight-0: #8b898b;
  --voting-cards-weight-1: #f44336;
  --voting-cards-weight-2: #ffc107;
  --voting-cards-weight-3: #4caf50;
}

.voting-voting_cards {
  $rectangle-width: 3rem;

  // ProposalM card
  &-card {
    a {
      margin-bottom: 0;

      > svg {
        margin-bottom: 2px;
        vertical-align: middle;
      }

      &.button {
        &.weight_0 {
          background-color: var(--voting-cards-weight-0);
        }

        &.weight_1 {
          background-color: var(--voting-cards-weight-1);
        }

        &.weight_2 {
          background-color: var(--voting-cards-weight-2);
        }

        &.weight_3 {
          background-color: var(--voting-cards-weight-3);
        }
      }
    }

    .votes_counter {
      .weight_0 {
        color: var(--voting-cards-weight-0);
      }

      .weight_1 {
        color: var(--voting-cards-weight-1);
      }

      .weight_2 {
        color: var(--voting-cards-weight-2);
      }

      .weight_3 {
        color: var(--voting-cards-weight-3);
      }
    }
  }

  margin-bottom: 1.5rem;
  border-bottom: 2px solid #d9d9d9;

  &.current-choice {
    text-align: center;
    margin-bottom: 0;
    border-bottom: none;

    .vote-card {
      margin-top: .5rem;

      .vote-label {
        margin-bottom: 1rem;
        color: $black;
      }
    }

    .change-vote-button:hover {
      font-size: 1rem;
      margin-top: 0;
    }
  }

  &.loading {
    opacity: .3;
    pointer-events: none;
    position: relative;

    &::before {
      @include spinner(25px, $medium-gray, var(--primary), 800ms);

      z-index: 1000;
      content: "";
      vertical-align: middle;
      position: absolute;
      top: calc(50% - 12.5px);
      left: calc(50% - 12.5px);
    }
  }

  .vote-title {
    text-align: center;
    font-size: 1.5rem;
  }

  .votes_counter {
    letter-spacing: .2rem;
    font-size: 1rem;
  }

  .vote-link.hidden {
    display: none;
  }

  .vote-card {
    display: inline-block;

    .vote-label {
      font-size: 1rem;
      margin-bottom: 0;
    }

    > svg {
      display: inline-block;
      width: $rectangle-width;
      height: calc($rectangle-width * 2);
      vertical-align: middle;
      margin: -.5rem 0 0 .5rem;
      max-width: 100%;
      transition: transform 150ms ease-in-out;

      &:hover {
        transform: scale(1.15) rotate(-5deg);
      }
    }

    &.weight_3 {
      color: var(--voting-cards-weight-3);

      & > svg {
        fill: var(--voting-cards-weight-3);
      }
    }

    &.weight_2 {
      color: var(--voting-cards-weight-2);

      & > svg {
        fill: var(--voting-cards-weight-2);
      }
    }

    &.weight_1 {
      color: var(--voting-cards-weight-1);

      & > svg {
        fill: var(--voting-cards-weight-1);
      }
    }

    &.voted {
      & > svg {
        transform: scale(1.15) rotate(-5deg);
      }
    }
  }

  .voting-button {
    background: none;
    border: none;
    color: #fff;
    font-weight: bold;
  }

  .dim {
    opacity: .3;
    pointer-events: none;
  }

  .disabled {
    pointer-events: none;
  }

  .vote-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;

    .vote-count {
      font-size: 2rem;
      font-weight: bolder;
      margin-bottom: 0;
    }
  }

  .change-vote-button {
    transition: all 150ms ease-in-out;

    &:hover {
      text-decoration: none;
      font-size: .9rem;
      margin-top: .1rem;
    }
  }

  .button.abstain-button {
    text-transform: uppercase;
    color: var(--voting-cards-weight-0);
    border-color: var(--voting-cards-weight-0);
    background-color: transparent;

    &.voted {
      background-color: var(--voting-cards-weight-0);
      color: #fff;
      border-color: transparent;

      &.disabled {
        opacity: 1;
      }
    }
  }
}
